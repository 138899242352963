'use strict';

import { render } from 'react-dom';

import './extensions/MathExtension';
import './extensions/StringExtension';
import './extensions/NumberExtension';

import { App } from './components';
import { DebugService } from '$services/debugService';
import { SoundService } from '$services/soundService';

SoundService.init();
DebugService.init();

render(<App />, document.getElementById('app-container'));
