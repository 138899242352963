'use strict';

import { Component, createRef, CSSProperties, RefObject } from 'react';
import { FloppyData } from '$models/floppyData';

import PlainDraggable from '$libs/plain-draggable.min.js';
import { FloppyService } from '$services/floppyService';
import { SoundService } from '$services/soundService';
PlainDraggable.draggableCursor = false;
PlainDraggable.draggingCursor = false;
PlainDraggable.draggableClass = 'plain-draggable-drag';
PlainDraggable.draggingClass = 'plain-draggable-dragging';

interface FloppyProps {
    data: FloppyData;
    style?: CSSProperties;
}

/**
 * Console component
 * @class
 */
export class Floppy extends Component<FloppyProps, any> {
    private ref: RefObject<HTMLImageElement>;
    private drag: PlainDraggable;

    public constructor(props: any) {
        super(props);

        this.ref = createRef();
        this.state = {};
    }

    private onDragEnd($ev: any): void {
        if (FloppyService.isLoaded()) return;

        const sWStart: number = 135;
        const sWEnd: number = 115;

        if ($ev.left < sWStart - window.innerWidth || $ev.left > sWEnd + window.innerWidth) return;
        if ($ev.top > 40) return;

        SoundService.get('insert').play();
        FloppyService.load(this.props.data.id);
    }

    public componentDidMount(): void {
        this.drag = new PlainDraggable(this.ref.current, { zIndex: 11, onDragEnd: this.onDragEnd.bind(this) });
    }

    /**
     * React render method
     *
     * @returns {any}
     */
    public render(): JSX.Element {
        if (!this.props.data) return null;

        return (
            <div
                id={this.props.data.id}
                style={{ ...this.props.style, ...{ backgroundColor: this.props.data.color } }}
                ref={this.ref}
                className="floppy">
                <img src="../../assets/img/caw_console/cc_floppy.png" className="floppy-backgrounds" />
                <img src="../../assets/img/caw_console/cc_floppy_cl.png" style={{ opacity: 0.5 }} className="floppy-backgrounds" />
            </div>
        );
    }
}
