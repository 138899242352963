'use strict';

import { Sound } from '$models/sound';
import { DebugService } from './debugService';

declare const window: any;
export class SoundService {
    private static sounds: { [id: string]: Sound };

    public static init(): void {
        window.soundService = this;
    }

    public static loadSounds(onLoaded: () => void): void {
        this.sounds = {};

        Promise.all([
            this.loadSound('access_1', './assets/snd/floppy_access1.ogg'),
            this.loadSound('access_2', './assets/snd/floppy_access2.ogg'),
            this.loadSound('access_3', './assets/snd/floppy_access3.ogg'),
            this.loadSound('access_4', './assets/snd/floppy_access4.ogg'),
            this.loadSound('access_5', './assets/snd/floppy_access5.ogg'),
            this.loadSound('access_6', './assets/snd/floppy_access6.ogg'),
            this.loadSound('eject', './assets/snd/floppy_eject.ogg'),
            this.loadSound('insert', './assets/snd/floppy_insert.ogg'),
            this.loadSound('running', './assets/snd/computer_running.ogg'),
        ]).then(() => onLoaded());
    }

    public static get(id: string): Sound {
        const snd: Sound = this.sounds[id];
        if (!snd) throw new Error(`Failed to find sound {${id}}`);

        return snd;
    }

    public static has(id: string): boolean {
        return this.sounds[id] != null;
    }

    public static loadSound(id: string, path: string, onLoaded: () => void = null): Promise<void> {
        if (this.sounds[id] != null) return Promise.reject(`Sound {${id}} already loaded`);

        return new Promise<void>((resolve: any, reject: any) => {
            const snd: Sound = new Sound(path);
            snd.load((err: string) => {
                if (err) return reject(err);
                DebugService.onDebug('SoundService', `Loaded sound {${path}}`);

                this.sounds[id] = snd;
                if (onLoaded) onLoaded(); // Lua does not support promise
                resolve();
            });
        });
    }
}
