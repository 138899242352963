declare const window: any;

export class Sound {
    public static ctx: AudioContext = new (window.AudioContext || window.webkitAudioContext)({ latencyHint: 'playback' });

    private source: AudioBufferSourceNode;
    private buffer: AudioBuffer;
    private gain: GainNode;

    private path: string;
    private loop: boolean;
    private volume: number;

    public constructor(path: string) {
        this.path = path;
        this.loop = false;
        this.volume = 1;
    }

    public load(onLoad: (err: string) => void): void {
        fetch(this.path)
            .then((data: Response) => {
                return data.arrayBuffer();
            })
            .then((buffer: ArrayBuffer) => {
                Sound.ctx.decodeAudioData(buffer, (decoded: AudioBuffer) => {
                    if (!decoded) return onLoad(`Failed to decode audio {${this.path}}`);
                    this.buffer = decoded;
                    return onLoad(null);
                });
            });
    }

    public play(): void {
        if (this.buffer == null) return;

        this.gain = Sound.ctx.createGain();
        this.gain.gain.value = this.volume;
        this.gain.connect(Sound.ctx.destination);

        this.source = Sound.ctx.createBufferSource();
        this.source.buffer = this.buffer;
        this.source.loop = this.loop;

        this.source.connect(this.gain);
        this.source.start();
        this.source.addEventListener('ended', () => this.clean(), { once: true });
    }

    public stop(): void {
        if (this.source == null) return;

        this.source.stop();
        this.clean();
    }

    /** From 0 to 1 */
    public setVolume(volume: number): Sound {
        this.volume = volume;
        if (this.gain != null) this.gain.gain.value = volume;

        return this;
    }

    public setLoop(loop: boolean): Sound {
        this.loop = loop;
        return this;
    }

    private clean(): void {
        this.source = null;
        this.gain = null;
    }
}
