'use strict';

import { Component, createRef, RefObject } from 'react';
import { HookSubscription } from '@edunad/hooks';

import { SoundService } from '$services/soundService';
import { Console } from '$components/console';
import { FloppyService } from '$services/floppyService';
import { FloppyData } from '$models/floppyData';
import { Floppy } from './floppy';
//declare let __PRODUCTION__: boolean;

interface AppState {
    initialized: boolean;

    floppies: FloppyData[];
    loadedFloppy: FloppyData;
}

/**
 * App component
 * @class
 */
export class App extends Component<any, AppState> {
    private ref: RefObject<HTMLDivElement>;

    private floppyLoadSubscription: HookSubscription;
    private floppyUnLoadSubscription: HookSubscription;

    public constructor(props: any) {
        super(props);

        this.ref = createRef();
        this.state = {
            initialized: false,
            floppies: [],
            loadedFloppy: null,
        };
    }

    public componentWillUnmount(): void {
        this.floppyLoadSubscription.destroy();
        this.floppyUnLoadSubscription.destroy();
    }

    public componentDidMount(): void {
        SoundService.loadSounds(() => {
            FloppyService.init(this.ref.current);
            FloppyService.loadFloppies(() => {
                FloppyService.load('caw');
                this.setState({ initialized: true, floppies: Object.values(FloppyService.getAll()) });
            });
        });

        this.floppyLoadSubscription = FloppyService.onFloppyLoad.addUnique((flp: FloppyData) => this.setState({ loadedFloppy: flp }));
        this.floppyUnLoadSubscription = FloppyService.onFloppyUnload.addUnique(() => this.setState({ loadedFloppy: null }));
    }

    private renderFloppies(): JSX.Element[] {
        if (!this.state.initialized) return null;

        return this.state.floppies.map((floppy: FloppyData, indx: number) => {
            if (this.state.loadedFloppy?.id == floppy.id) return null;
            return <Floppy style={{ top: indx * 110 + 10, left: 10 }} key={floppy.id} data={floppy} />;
        });
    }

    /**
     * React render method
     *
     * @returns {any}
     */
    public render(): any {
        return (
            <div className="app-container">
                <div className="app-background-overlay"></div>
                <div ref={this.ref} id="app-sandbox"></div>

                <Console />
                {this.renderFloppies()}
            </div>
        );
    }
}
