/*! PlainDraggable v2.5.12 (c) anseki https://anseki.github.io/plain-draggable/ */
module.exports = (function (t) {
    var e = {};
    function n(r) {
        if (e[r]) return e[r].exports;
        var o = (e[r] = { i: r, l: !1, exports: {} });
        return t[r].call(o.exports, o, o.exports, n), (o.l = !0), o.exports;
    }
    return (
        (n.m = t),
        (n.c = e),
        (n.d = function (t, e, r) {
            n.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: r });
        }),
        (n.r = function (t) {
            'undefined' != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, { value: 'Module' }),
                Object.defineProperty(t, '__esModule', { value: !0 });
        }),
        (n.t = function (t, e) {
            if ((1 & e && (t = n(t)), 8 & e)) return t;
            if (4 & e && 'object' == typeof t && t && t.__esModule) return t;
            var r = Object.create(null);
            if ((n.r(r), Object.defineProperty(r, 'default', { enumerable: !0, value: t }), 2 & e && 'string' != typeof t))
                for (var o in t)
                    n.d(
                        r,
                        o,
                        function (e) {
                            return t[e];
                        }.bind(null, o),
                    );
            return r;
        }),
        (n.n = function (t) {
            var e =
                t && t.__esModule
                    ? function () {
                          return t.default;
                      }
                    : function () {
                          return t;
                      };
            return n.d(e, 'a', e), e;
        }),
        (n.o = function (t, e) {
            return Object.prototype.hasOwnProperty.call(t, e);
        }),
        (n.p = ''),
        n((n.s = 0))
    );
})([
    function (t, e, n) {
        'use strict';
        n.r(e);
        var r = [],
            o =
                window.requestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (t) {
                    return setTimeout(t, 1e3 / 60);
                },
            i =
                window.cancelAnimationFrame ||
                window.mozCancelAnimationFrame ||
                window.webkitCancelAnimationFrame ||
                window.msCancelAnimationFrame ||
                function (t) {
                    return clearTimeout(t);
                },
            a = Date.now(),
            l = void 0;
        function u() {
            var t = void 0,
                e = void 0;
            l && (i.call(window, l), (l = null)),
                r.forEach(function (e) {
                    var n;
                    (n = e.event) && ((e.event = null), e.listener(n), (t = !0));
                }),
                t ? ((a = Date.now()), (e = !0)) : Date.now() - a < 500 && (e = !0),
                e && (l = o.call(window, u));
        }
        function s(t) {
            var e = -1;
            return (
                r.some(function (n, r) {
                    return n.listener === t && ((e = r), !0);
                }),
                e
            );
        }
        var d = {
                add: function (t) {
                    var e = void 0;
                    return -1 === s(t)
                        ? (r.push((e = { listener: t })),
                          function (t) {
                              (e.event = t), l || u();
                          })
                        : null;
                },
                remove: function (t) {
                    var e;
                    (e = s(t)) > -1 && (r.splice(e, 1), !r.length && l && (i.call(window, l), (l = null)));
                },
            },
            c = (function () {
                function t(t, e) {
                    for (var n = 0; n < e.length; n++) {
                        var r = e[n];
                        (r.enumerable = r.enumerable || !1),
                            (r.configurable = !0),
                            'value' in r && (r.writable = !0),
                            Object.defineProperty(t, r.key, r);
                    }
                }
                return function (e, n, r) {
                    return n && t(e.prototype, n), r && t(e, r), e;
                };
            })();
        var f = !1;
        try {
            window.addEventListener(
                'test',
                null,
                Object.defineProperty({}, 'passive', {
                    get: function () {
                        f = !0;
                    },
                }),
            );
        } catch (t) {}
        function p(t, e, n, r) {
            t.addEventListener(e, n, f ? r : r.capture);
        }
        function v(t, e) {
            if (null != t && null != e) for (var n = 0; n < t.length; n++) if (t[n].identifier === e) return t[n];
            return null;
        }
        function h(t) {
            return t && 'number' == typeof t.clientX && 'number' == typeof t.clientY;
        }
        function m(t) {
            t.preventDefault();
        }
        var g = (function () {
            function t(e) {
                var n = this;
                !(function (t, e) {
                    if (!(t instanceof e)) throw new TypeError('Cannot call a class as a function');
                })(this, t),
                    (this.startHandlers = {}),
                    (this.lastHandlerId = 0),
                    (this.curPointerClass = null),
                    (this.curTouchId = null),
                    (this.lastPointerXY = { clientX: 0, clientY: 0 }),
                    (this.lastTouchTime = 0),
                    (this.options = { preventDefault: !0, stopPropagation: !0 }),
                    e &&
                        ['preventDefault', 'stopPropagation'].forEach(function (t) {
                            'boolean' == typeof e[t] && (n.options[t] = e[t]);
                        });
            }
            return (
                c(
                    t,
                    [
                        {
                            key: 'regStartHandler',
                            value: function (t) {
                                var e = this;
                                return (
                                    (e.startHandlers[++e.lastHandlerId] = function (n) {
                                        var r = 'mousedown' === n.type ? 'mouse' : 'touch',
                                            o = Date.now(),
                                            i = void 0,
                                            a = void 0;
                                        if ('touch' === r)
                                            (e.lastTouchTime = o), (i = n.changedTouches[0]), (a = n.changedTouches[0].identifier);
                                        else {
                                            if (o - e.lastTouchTime < 400) return;
                                            i = n;
                                        }
                                        if (!h(i)) throw new Error('No clientX/clientY');
                                        e.curPointerClass && e.cancel(),
                                            t.call(e, i) &&
                                                ((e.curPointerClass = r),
                                                (e.curTouchId = 'touch' === r ? a : null),
                                                (e.lastPointerXY.clientX = i.clientX),
                                                (e.lastPointerXY.clientY = i.clientY),
                                                e.options.preventDefault && n.preventDefault(),
                                                e.options.stopPropagation && n.stopPropagation());
                                    }),
                                    e.lastHandlerId
                                );
                            },
                        },
                        {
                            key: 'unregStartHandler',
                            value: function (t) {
                                delete this.startHandlers[t];
                            },
                        },
                        {
                            key: 'addStartHandler',
                            value: function (t, e) {
                                if (!this.startHandlers[e]) throw new Error('Invalid handlerId: ' + e);
                                return (
                                    p(t, 'mousedown', this.startHandlers[e], { capture: !1, passive: !1 }),
                                    p(t, 'touchstart', this.startHandlers[e], { capture: !1, passive: !1 }),
                                    p(t, 'dragstart', m, { capture: !1, passive: !1 }),
                                    e
                                );
                            },
                        },
                        {
                            key: 'removeStartHandler',
                            value: function (t, e) {
                                if (!this.startHandlers[e]) throw new Error('Invalid handlerId: ' + e);
                                return (
                                    t.removeEventListener('mousedown', this.startHandlers[e], !1),
                                    t.removeEventListener('touchstart', this.startHandlers[e], !1),
                                    t.removeEventListener('dragstart', m, !1),
                                    e
                                );
                            },
                        },
                        {
                            key: 'addMoveHandler',
                            value: function (t, e) {
                                var n = this,
                                    r = d.add(function (t) {
                                        var e = 'mousemove' === t.type ? 'mouse' : 'touch';
                                        if (('touch' === e && (n.lastTouchTime = Date.now()), e === n.curPointerClass)) {
                                            var r = 'touch' === e ? v(t.changedTouches, n.curTouchId) : t;
                                            h(r) &&
                                                ((r.clientX === n.lastPointerXY.clientX && r.clientY === n.lastPointerXY.clientY) ||
                                                    n.move(r),
                                                n.options.preventDefault && t.preventDefault(),
                                                n.options.stopPropagation && t.stopPropagation());
                                        }
                                    });
                                p(t, 'mousemove', r, { capture: !1, passive: !1 }),
                                    p(t, 'touchmove', r, { capture: !1, passive: !1 }),
                                    (n.curMoveHandler = e);
                            },
                        },
                        {
                            key: 'move',
                            value: function (t) {
                                h(t) && ((this.lastPointerXY.clientX = t.clientX), (this.lastPointerXY.clientY = t.clientY)),
                                    this.curMoveHandler && this.curMoveHandler(this.lastPointerXY);
                            },
                        },
                        {
                            key: 'addEndHandler',
                            value: function (t, e) {
                                var n = this;
                                function r(t) {
                                    var e = 'mouseup' === t.type ? 'mouse' : 'touch';
                                    if (('touch' === e && (n.lastTouchTime = Date.now()), e === n.curPointerClass)) {
                                        var r =
                                            'touch' === e
                                                ? v(t.changedTouches, n.curTouchId) || (v(t.touches, n.curTouchId) ? null : {})
                                                : t;
                                        r &&
                                            (n.end(r),
                                            n.options.preventDefault && t.preventDefault(),
                                            n.options.stopPropagation && t.stopPropagation());
                                    }
                                }
                                p(t, 'mouseup', r, { capture: !1, passive: !1 }),
                                    p(t, 'touchend', r, { capture: !1, passive: !1 }),
                                    (n.curEndHandler = e);
                            },
                        },
                        {
                            key: 'end',
                            value: function (t) {
                                h(t) && ((this.lastPointerXY.clientX = t.clientX), (this.lastPointerXY.clientY = t.clientY)),
                                    this.curEndHandler && this.curEndHandler(this.lastPointerXY),
                                    (this.curPointerClass = this.curTouchId = null);
                            },
                        },
                        {
                            key: 'addCancelHandler',
                            value: function (t, e) {
                                var n = this;
                                p(
                                    t,
                                    'touchcancel',
                                    function (t) {
                                        (n.lastTouchTime = Date.now()),
                                            null != n.curPointerClass &&
                                                (v(t.changedTouches, n.curTouchId) || (v(t.touches, n.curTouchId) ? null : {})) &&
                                                n.cancel();
                                    },
                                    { capture: !1, passive: !1 },
                                ),
                                    (n.curCancelHandler = e);
                            },
                        },
                        {
                            key: 'cancel',
                            value: function () {
                                this.curCancelHandler && this.curCancelHandler(), (this.curPointerClass = this.curTouchId = null);
                            },
                        },
                    ],
                    [
                        {
                            key: 'addEventListenerWithOptions',
                            get: function () {
                                return p;
                            },
                        },
                    ],
                ),
                t
            );
        })();
        function y(t) {
            return t.substr(0, 1).toUpperCase() + t.substr(1);
        }
        var x,
            w,
            b,
            S,
            E = ['webkit', 'moz', 'ms', 'o'],
            T = E.reduce(function (t, e) {
                return t.push(e), t.push(y(e)), t;
            }, []),
            B = E.map(function (t) {
                return '-' + t + '-';
            }),
            C =
                ((S = void 0),
                function () {
                    return (S = S || document.createElement('div').style);
                }),
            O =
                ((w = new RegExp('^(?:' + E.join('|') + ')(.)', 'i')),
                (b = /[A-Z]/),
                function (t) {
                    return 'float' ===
                        (t = (t + '')
                            .replace(/\s/g, '')
                            .replace(/-([\da-z])/gi, function (t, e) {
                                return e.toUpperCase();
                            })
                            .replace(w, function (t, e) {
                                return b.test(e) ? e.toLowerCase() : t;
                            })).toLowerCase()
                        ? 'cssFloat'
                        : t;
                }),
            H =
                ((x = new RegExp('^(?:' + B.join('|') + ')', 'i')),
                function (t) {
                    return (null != t ? t + '' : '').replace(/\s/g, '').replace(x, '');
                }),
            k = function (t, e) {
                var n = C();
                return (
                    (t = t.replace(/[A-Z]/g, function (t) {
                        return '-' + t.toLowerCase();
                    })),
                    n.setProperty(t, e),
                    null != n[t] && n.getPropertyValue(t) === e
                );
            },
            I = {},
            P = {};
        function _(t) {
            if ((t = O(t)) && null == I[t]) {
                var e = C();
                if (null != e[t]) I[t] = t;
                else {
                    var n = y(t);
                    T.some(function (r) {
                        var o = r + n;
                        return null != e[o] && ((I[t] = o), !0);
                    }) || (I[t] = !1);
                }
            }
            return I[t] || void 0;
        }
        var D = {
            getName: _,
            getValue: function (t, e) {
                var n = void 0;
                return (t = _(t))
                    ? ((P[t] = P[t] || {}),
                      (Array.isArray(e) ? e : [e]).some(function (e) {
                          return (
                              (e = H(e)),
                              null != P[t][e]
                                  ? !1 !== P[t][e] && ((n = P[t][e]), !0)
                                  : k(t, e)
                                  ? ((n = P[t][e] = e), !0)
                                  : !!B.some(function (r) {
                                        var o = r + e;
                                        return !!k(t, o) && ((n = P[t][e] = o), !0);
                                    }) || ((P[t][e] = !1), !1)
                          );
                      }),
                      'string' == typeof n ? n : void 0)
                    : n;
            },
        };
        function X(t) {
            return (t + '').trim();
        }
        function Y(t, e) {
            e.setAttribute('class', t.join(' '));
        }
        function L(t, e, n) {
            n.filter(function (e) {
                return !(!(e = X(e)) || -1 !== t.indexOf(e)) && (t.push(e), !0);
            }).length && Y(t, e);
        }
        function A(t, e, n) {
            n.filter(function (e) {
                var n = void 0;
                return !(!(e = X(e)) || -1 === (n = t.indexOf(e))) && (t.splice(n, 1), !0);
            }).length && Y(t, e);
        }
        function F(t) {
            return (
                (!F.ignoreNative && t.classList) ||
                ((e = (t.getAttribute('class') || '')
                    .trim()
                    .split(/\s+/)
                    .filter(function (t) {
                        return !!t;
                    })),
                (n = {
                    length: e.length,
                    item: function (t) {
                        return e[t];
                    },
                    contains: function (t) {
                        return -1 !== e.indexOf(X(t));
                    },
                    add: function () {
                        return L(e, t, Array.prototype.slice.call(arguments)), F.methodChain ? n : void 0;
                    },
                    remove: function () {
                        return A(e, t, Array.prototype.slice.call(arguments)), F.methodChain ? n : void 0;
                    },
                    toggle: function (n, r) {
                        return (function (t, e, n, r) {
                            var o = t.indexOf((n = X(n)));
                            return -1 !== o ? !!r || (t.splice(o, 1), Y(t, e), !1) : !1 !== r && (t.push(n), Y(t, e), !0);
                        })(e, t, n, r);
                    },
                    replace: function (r, o) {
                        return (
                            (function (t, e, n, r) {
                                var o = void 0;
                                (n = X(n)) &&
                                    (r = X(r)) &&
                                    n !== r &&
                                    -1 !== (o = t.indexOf(n)) &&
                                    (t.splice(o, 1), -1 === t.indexOf(r) && t.push(r), Y(t, e));
                            })(e, t, r, o),
                            F.methodChain ? n : void 0
                        );
                    },
                }))
            );
            var e, n;
        }
        F.methodChain = !0;
        var W = F,
            j = (function () {
                function t(t, e) {
                    for (var n = 0; n < e.length; n++) {
                        var r = e[n];
                        (r.enumerable = r.enumerable || !1),
                            (r.configurable = !0),
                            'value' in r && (r.writable = !0),
                            Object.defineProperty(t, r.key, r);
                    }
                }
                return function (e, n, r) {
                    return n && t(e.prototype, n), r && t(e, r), e;
                };
            })(),
            R =
                'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
                    ? function (t) {
                          return typeof t;
                      }
                    : function (t) {
                          return t && 'function' == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype
                              ? 'symbol'
                              : typeof t;
                      };
        W.ignoreNative = !0;
        var M,
            z,
            N,
            V = ['tl', 'tr', 'bl', 'br'],
            G = ['start', 'end'],
            q = ['inside', 'outside'],
            U = [40, 200, 1e3],
            Z = [100, 40, 0],
            $ =
                '-ms-scroll-limit' in document.documentElement.style &&
                '-ms-ime-align' in document.documentElement.style &&
                !window.navigator.msPointerEnabled,
            J = !$ && !!document.uniqueID,
            K = 'MozAppearance' in document.documentElement.style,
            Q = !($ || K || !window.chrome || !window.CSS),
            tt = !($ || J || K || Q || window.chrome || !('WebkitAppearance' in document.documentElement.style)),
            et =
                ((M = {}.toString),
                (z = {}.hasOwnProperty.toString),
                (N = z.call(Object)),
                function (t) {
                    var e = void 0,
                        n = void 0;
                    return (
                        t &&
                        '[object Object]' === M.call(t) &&
                        (!(e = Object.getPrototypeOf(t)) ||
                            ((n = e.hasOwnProperty('constructor') && e.constructor) && 'function' == typeof n && z.call(n) === N))
                    );
                }),
            nt =
                Number.isFinite ||
                function (t) {
                    return 'number' == typeof t && window.isFinite(t);
                },
            rt = {},
            ot = {},
            it = new g(),
            at = 0,
            lt = void 0,
            ut = void 0,
            st = void 0,
            dt = void 0,
            ct = void 0,
            ft = void 0,
            pt = void 0,
            vt = void 0,
            ht = void 0,
            mt = void 0,
            gt = tt ? ['all-scroll', 'move'] : ['grab', 'all-scroll', 'move'],
            yt = tt ? 'move' : ['grabbing', 'move'],
            xt = 'plain-draggable',
            wt = 'plain-draggable-dragging',
            bt = 'plain-draggable-moving',
            St = {},
            Et =
                window.requestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (t) {
                    return setTimeout(t, 1e3 / 60);
                },
            Tt =
                window.cancelAnimationFrame ||
                window.mozCancelAnimationFrame ||
                window.webkitCancelAnimationFrame ||
                window.msCancelAnimationFrame ||
                function (t) {
                    return clearTimeout(t);
                },
            Bt = function () {
                var t = Date.now();
                ['x', 'y'].forEach(function (e) {
                    var n = Ot[e];
                    if (n) {
                        var r = t - n.lastFrameTime,
                            o = kt(Ht, e),
                            i = null != n.lastValue && Math.abs(n.lastValue - o) < 10 ? n.lastValue : o;
                        if (-1 === n.dir ? i > n.min : i < n.max) {
                            var a = i + n.speed * r * n.dir;
                            a < n.min ? (a = n.min) : a > n.max && (a = n.max), kt(Ht, e, a), (n.lastValue = a);
                        }
                        n.lastFrameTime = t;
                    }
                });
            },
            Ct = function t() {
                Tt.call(window, It), Bt(), (It = Et.call(window, t));
            },
            Ot = {},
            Ht = void 0,
            kt = void 0,
            It = void 0;
        function Pt(t, e, n) {
            return (
                null != n && ('x' === e ? t.scrollTo(n, t.pageYOffset) : t.scrollTo(t.pageXOffset, n)),
                'x' === e ? t.pageXOffset : t.pageYOffset
            );
        }
        function _t(t, e, n) {
            var r = 'x' === e ? 'scrollLeft' : 'scrollTop';
            return null != n && (t[r] = n), t[r];
        }
        function Dt(t) {
            return t
                ? et(t)
                    ? Object.keys(t).reduce(function (e, n) {
                          return (e[n] = Dt(t[n])), e;
                      }, {})
                    : Array.isArray(t)
                    ? t.map(Dt)
                    : t
                : t;
        }
        function Xt(t, e) {
            var n = void 0,
                r = void 0;
            return (
                (void 0 === t ? 'undefined' : R(t)) !== (void 0 === e ? 'undefined' : R(e)) ||
                (n = et(t) ? 'obj' : Array.isArray(t) ? 'array' : '') != (et(e) ? 'obj' : Array.isArray(e) ? 'array' : '') ||
                ('obj' === n
                    ? Xt((r = Object.keys(t).sort()), Object.keys(e).sort()) ||
                      r.some(function (n) {
                          return Xt(t[n], e[n]);
                      })
                    : 'array' === n
                    ? t.length !== e.length ||
                      t.some(function (t, n) {
                          return Xt(t, e[n]);
                      })
                    : t !== e)
            );
        }
        function Yt(t) {
            return !(
                !t ||
                t.nodeType !== Node.ELEMENT_NODE ||
                'function' != typeof t.getBoundingClientRect ||
                t.compareDocumentPosition(document) & Node.DOCUMENT_POSITION_DISCONNECTED
            );
        }
        function Lt(t) {
            if (!et(t)) return null;
            var e = void 0;
            if (!nt((e = t.left)) && !nt((e = t.x))) return null;
            if (((t.left = t.x = e), !nt((e = t.top)) && !nt((e = t.y)))) return null;
            if (((t.top = t.y = e), nt(t.width) && t.width >= 0)) t.right = t.left + t.width;
            else {
                if (!(nt(t.right) && t.right >= t.left)) return null;
                t.width = t.right - t.left;
            }
            if (nt(t.height) && t.height >= 0) t.bottom = t.top + t.height;
            else {
                if (!(nt(t.bottom) && t.bottom >= t.top)) return null;
                t.height = t.bottom - t.top;
            }
            return t;
        }
        function At(t) {
            return nt(t)
                ? { value: t, isRatio: !1 }
                : 'string' == typeof t
                ? (function (t) {
                      var e = /^(.+?)(%)?$/.exec(t),
                          n = void 0,
                          r = void 0;
                      return e && nt((n = parseFloat(e[1]))) ? { value: (r = !(!e[2] || !n)) ? n / 100 : n, isRatio: r } : null;
                  })(t.replace(/\s/g, ''))
                : null;
        }
        function Ft(t) {
            return t.isRatio ? 100 * t.value + '%' : t.value;
        }
        function Wt(t, e, n) {
            return 'number' == typeof t ? t : e + t.value * (t.isRatio ? n : 1);
        }
        function jt(t) {
            if (!et(t)) return null;
            var e = void 0;
            if (!(e = At(t.left)) && !(e = At(t.x))) return null;
            if (((t.left = t.x = e), !(e = At(t.top)) && !(e = At(t.y)))) return null;
            if (((t.top = t.y = e), (e = At(t.width)) && e.value >= 0)) (t.width = e), delete t.right;
            else {
                if (!(e = At(t.right))) return null;
                (t.right = e), delete t.width;
            }
            if ((e = At(t.height)) && e.value >= 0) (t.height = e), delete t.bottom;
            else {
                if (!(e = At(t.bottom))) return null;
                (t.bottom = e), delete t.height;
            }
            return t;
        }
        function Rt(t) {
            return Object.keys(t).reduce(function (e, n) {
                return (e[n] = Ft(t[n])), e;
            }, {});
        }
        function Mt(t, e) {
            var n = { left: 'x', right: 'x', x: 'x', width: 'x', top: 'y', bottom: 'y', y: 'y', height: 'y' },
                r = { x: e.left, y: e.top },
                o = { x: e.width, y: e.height };
            return Lt(
                Object.keys(t).reduce(function (e, i) {
                    return (e[i] = Wt(t[i], 'width' === i || 'height' === i ? 0 : r[n[i]], o[n[i]])), e;
                }, {}),
            );
        }
        function zt(t, e) {
            var n = t.getBoundingClientRect(),
                r = { left: n.left, top: n.top, width: n.width, height: n.height };
            if (((r.left += window.pageXOffset), (r.top += window.pageYOffset), e)) {
                var o = window.getComputedStyle(t, ''),
                    i = parseFloat(o.borderTopWidth) || 0,
                    a = parseFloat(o.borderRightWidth) || 0,
                    l = parseFloat(o.borderBottomWidth) || 0,
                    u = parseFloat(o.borderLeftWidth) || 0;
                (r.left += u), (r.top += i), (r.width -= u + a), (r.height -= i + l);
            }
            return Lt(r);
        }
        function Nt(t, e) {
            null == dt && (!1 !== gt && (dt = D.getValue('cursor', gt)), null == dt && (dt = !1)), (t.style.cursor = !1 === dt ? e : dt);
        }
        function Vt(t) {
            null == ct && (!1 !== yt && (ct = D.getValue('cursor', yt)), null == ct && (ct = !1)), !1 !== ct && (t.style.cursor = ct);
        }
        function Gt(t, e, n) {
            var r = t.svgPoint;
            return (r.x = e), (r.y = n), r.matrixTransform(t.svgCtmElement.getScreenCTM().inverse());
        }
        function qt(t, e) {
            var n = t.elementBBox;
            if (e.left !== n.left || e.top !== n.top) {
                var r = t.htmlOffset;
                return (t.elementStyle[vt] = 'translate(' + (e.left + r.left) + 'px, ' + (e.top + r.top) + 'px)'), !0;
            }
            return !1;
        }
        function Ut(t, e) {
            var n = t.elementBBox,
                r = t.elementStyle,
                o = t.htmlOffset,
                i = !1;
            return (
                e.left !== n.left && ((r.left = e.left + o.left + 'px'), (i = !0)),
                e.top !== n.top && ((r.top = e.top + o.top + 'px'), (i = !0)),
                i
            );
        }
        function Zt(t, e) {
            var n = t.elementBBox;
            if (e.left !== n.left || e.top !== n.top) {
                var r = t.svgOffset,
                    o = t.svgOriginBBox,
                    i = Gt(t, e.left - window.pageXOffset, e.top - window.pageYOffset);
                return t.svgTransform.setTranslate(i.x + r.x - o.x, i.y + r.y - o.y), !0;
            }
            return !1;
        }
        function $t(t, e, n) {
            var r = t.elementBBox;
            function o() {
                t.minLeft >= t.maxLeft
                    ? (e.left = r.left)
                    : e.left < t.minLeft
                    ? (e.left = t.minLeft)
                    : e.left > t.maxLeft && (e.left = t.maxLeft),
                    t.minTop >= t.maxTop ? (e.top = r.top) : e.top < t.minTop ? (e.top = t.minTop) : e.top > t.maxTop && (e.top = t.maxTop);
            }
            if ((o(), n)) {
                if (!1 === n(e)) return !1;
                o();
            }
            var i = t.moveElm(t, e);
            return i && (t.elementBBox = Lt({ left: e.left, top: e.top, width: r.width, height: r.height })), i;
        }
        function Jt(t) {
            var e = t.element,
                n = t.elementStyle,
                r = zt(e),
                o = ['display', 'marginTop', 'marginBottom', 'width', 'height'];
            o.unshift(vt);
            var i = n[pt];
            n[pt] = 'none';
            var a = zt(e);
            t.orgStyle
                ? o.forEach(function (e) {
                      (null != t.lastStyle[e] && n[e] !== t.lastStyle[e]) || (n[e] = t.orgStyle[e]);
                  })
                : ((t.orgStyle = o.reduce(function (t, e) {
                      return (t[e] = n[e] || ''), t;
                  }, {})),
                  (t.lastStyle = {}));
            var l = zt(e),
                u = window.getComputedStyle(e, '');
            'inline' === u.display &&
                ((n.display = 'inline-block'),
                ['Top', 'Bottom'].forEach(function (t) {
                    var e = parseFloat(u['padding' + t]);
                    n['margin' + t] = e ? '-' + e + 'px' : '0';
                })),
                (n[vt] = 'translate(0, 0)');
            var s = zt(e),
                d = (t.htmlOffset = { left: s.left ? -s.left : 0, top: s.top ? -s.top : 0 });
            return (
                (n[vt] = 'translate(' + (r.left + d.left) + 'px, ' + (r.top + d.top) + 'px)'),
                ['width', 'height'].forEach(function (r) {
                    s[r] !== l[r] && ((n[r] = l[r] + 'px'), (s = zt(e))[r] !== l[r] && (n[r] = l[r] - (s[r] - l[r]) + 'px')),
                        (t.lastStyle[r] = n[r]);
                }),
                e.offsetWidth,
                (n[pt] = i),
                (a.left === r.left && a.top === r.top) || (n[vt] = 'translate(' + (a.left + d.left) + 'px, ' + (a.top + d.top) + 'px)'),
                a
            );
        }
        function Kt(t) {
            var e = t.element,
                n = t.elementStyle,
                r = zt(e),
                o = ['position', 'marginTop', 'marginRight', 'marginBottom', 'marginLeft', 'width', 'height'],
                i = n[pt];
            n[pt] = 'none';
            var a = zt(e);
            t.orgStyle
                ? o.forEach(function (e) {
                      (null != t.lastStyle[e] && n[e] !== t.lastStyle[e]) || (n[e] = t.orgStyle[e]);
                  })
                : ((t.orgStyle = o.reduce(function (t, e) {
                      return (t[e] = n[e] || ''), t;
                  }, {})),
                  (t.lastStyle = {}));
            var l = zt(e);
            (n.position = 'absolute'), (n.left = n.top = n.margin = '0');
            var u = zt(e),
                s = (t.htmlOffset = { left: u.left ? -u.left : 0, top: u.top ? -u.top : 0 });
            return (
                (n.left = r.left + s.left + 'px'),
                (n.top = r.top + s.top + 'px'),
                ['width', 'height'].forEach(function (r) {
                    u[r] !== l[r] && ((n[r] = l[r] + 'px'), (u = zt(e))[r] !== l[r] && (n[r] = l[r] - (u[r] - l[r]) + 'px')),
                        (t.lastStyle[r] = n[r]);
                }),
                e.offsetWidth,
                (n[pt] = i),
                (a.left === r.left && a.top === r.top) || ((n.left = a.left + s.left + 'px'), (n.top = a.top + s.top + 'px')),
                a
            );
        }
        function Qt(t) {
            var e = t.element,
                n = t.svgTransform,
                r = e.getBoundingClientRect(),
                o = zt(e);
            n.setTranslate(0, 0);
            var i = (t.svgOriginBBox = e.getBBox()),
                a = e.getBoundingClientRect(),
                l = Gt(t, a.left, a.top),
                u = (t.svgOffset = { x: i.x - l.x, y: i.y - l.y }),
                s = Gt(t, r.left, r.top);
            return n.setTranslate(s.x + u.x - i.x, s.y + u.y - i.y), o;
        }
        function te(t, e) {
            var n = zt(document.documentElement),
                r = (t.elementBBox = t.initElm(t)),
                o = (t.containmentBBox = t.containmentIsBBox ? Mt(t.options.containment, n) || n : zt(t.options.containment, !0));
            if (
                ((t.minLeft = o.left),
                (t.maxLeft = o.right - r.width),
                (t.minTop = o.top),
                (t.maxTop = o.bottom - r.height),
                $t(t, { left: r.left, top: r.top }),
                t.parsedSnapTargets)
            ) {
                var i = { x: r.width, y: r.height },
                    a = { x: t.minLeft, y: t.minTop },
                    l = { x: t.maxLeft, y: t.maxTop },
                    u = {
                        left: 'x',
                        right: 'x',
                        x: 'x',
                        width: 'x',
                        xStart: 'x',
                        xEnd: 'x',
                        xStep: 'x',
                        top: 'y',
                        bottom: 'y',
                        y: 'y',
                        height: 'y',
                        yStart: 'y',
                        yEnd: 'y',
                        yStep: 'y',
                    },
                    s = t.parsedSnapTargets.reduce(function (t, e) {
                        var s = 'containment' === e.base ? o : n,
                            d = { x: s.left, y: s.top },
                            c = { x: s.width, y: s.height };
                        function f(n) {
                            if (
                                (null == n.center && (n.center = e.center),
                                null == n.xGravity && (n.xGravity = e.gravity),
                                null == n.yGravity && (n.yGravity = e.gravity),
                                null != n.x && null != n.y)
                            )
                                (n.x = Wt(n.x, d.x, c.x)),
                                    (n.y = Wt(n.y, d.y, c.y)),
                                    n.center && ((n.x -= i.x / 2), (n.y -= i.y / 2), (n.corners = ['tl'])),
                                    (n.corners || e.corners).forEach(function (e) {
                                        var r = n.x - ('tr' === e || 'br' === e ? i.x : 0),
                                            o = n.y - ('bl' === e || 'br' === e ? i.y : 0);
                                        if (r >= a.x && r <= l.x && o >= a.y && o <= l.y) {
                                            var u = { x: r, y: o },
                                                s = r - n.xGravity,
                                                d = r + n.xGravity,
                                                c = o - n.yGravity,
                                                f = o + n.yGravity;
                                            s > a.x && (u.gravityXStart = s),
                                                d < l.x && (u.gravityXEnd = d),
                                                c > a.y && (u.gravityYStart = c),
                                                f < l.y && (u.gravityYEnd = f),
                                                t.push(u);
                                        }
                                    });
                            else {
                                var r = null != n.x ? 'x' : 'y',
                                    o = 'x' === r ? 'y' : 'x',
                                    u = o + 'Start',
                                    s = o + 'End',
                                    f = r + 'Gravity',
                                    p = r.toUpperCase(),
                                    v = o.toUpperCase(),
                                    h = 'gravity' + p + 'Start',
                                    m = 'gravity' + p + 'End',
                                    g = 'gravity' + v + 'Start',
                                    y = 'gravity' + v + 'End';
                                if (
                                    ((n[r] = Wt(n[r], d[r], c[r])),
                                    (n[u] = Wt(n[u], d[o], c[o])),
                                    (n[s] = Wt(n[s], d[o], c[o]) - i[o]),
                                    n[u] > n[s] || n[u] > l[o] || n[s] < a[o])
                                )
                                    return;
                                n.center && ((n[r] -= i[r] / 2), (n.sides = ['start'])),
                                    (n.sides || e.sides).forEach(function (e) {
                                        var d = n[r] - ('end' === e ? i[r] : 0);
                                        if (d >= a[r] && d <= l[r]) {
                                            var c = {},
                                                p = d - n[f],
                                                v = d + n[f];
                                            (c[r] = d),
                                                p > a[r] && (c[h] = p),
                                                v < l[r] && (c[m] = v),
                                                n[u] > a[o] && (c[g] = n[u]),
                                                n[s] < l[o] && (c[y] = n[s]),
                                                t.push(c);
                                        }
                                    });
                            }
                        }
                        var p = void 0;
                        if ((p = e.element ? zt(e.element) : null) || e.ppBBox)
                            e.ppBBox && (p = Mt(e.ppBBox, s)),
                                p &&
                                    e.edges.forEach(function (t) {
                                        var n = e.gravity,
                                            o = e.gravity;
                                        'outside' === t && ((n += r.width), (o += r.height));
                                        var i = p.left - n,
                                            a = p.right + n,
                                            l = p.top - o,
                                            u = p.bottom + o,
                                            s = 'inside' === t ? 'start' : 'end';
                                        f({ xStart: i, xEnd: a, y: p.top, sides: [s], center: !1 }),
                                            f({ x: p.left, yStart: l, yEnd: u, sides: [s], center: !1 }),
                                            (s = 'inside' === t ? 'end' : 'start'),
                                            f({ xStart: i, xEnd: a, y: p.bottom, sides: [s], center: !1 }),
                                            f({ x: p.right, yStart: l, yEnd: u, sides: [s], center: !1 });
                                    });
                        else {
                            var v = [
                                ['x', 'y', 'xStart', 'xEnd', 'xStep', 'yStart', 'yEnd', 'yStep'].reduce(function (t, n) {
                                    return e[n] && (t[n] = Wt(e[n], 'xStep' === n || 'yStep' === n ? 0 : d[u[n]], c[u[n]])), t;
                                }, {}),
                            ];
                            ['x', 'y'].forEach(function (t) {
                                var n = t + 'Start',
                                    r = t + 'End',
                                    o = t + 'Step',
                                    i = t + 'Gravity';
                                v = v.reduce(function (a, l) {
                                    var u = l[n],
                                        s = l[r],
                                        d = l[o];
                                    if (null != u && null != s && u >= s) return a;
                                    if (null != d) {
                                        if (d < 2) return a;
                                        var c = d / 2;
                                        c = e.gravity > c ? c : null;
                                        for (var f = u; f <= s; f += d) {
                                            var p = Object.keys(l).reduce(function (t, e) {
                                                return e !== n && e !== r && e !== o && (t[e] = l[e]), t;
                                            }, {});
                                            (p[t] = f), (p[i] = c), a.push(p);
                                        }
                                    } else a.push(l);
                                    return a;
                                }, []);
                            }),
                                v.forEach(function (t) {
                                    f(t);
                                });
                        }
                        return t;
                    }, []);
                t.snapTargets = s.length ? s : null;
            }
            var d = {},
                c = t.options.autoScroll;
            if (c) {
                (d.isWindow = c.target === window), (d.target = c.target);
                var f = 'scroll' === e,
                    p = (function (t, e, n) {
                        var r,
                            o = {},
                            i = void 0,
                            a = void 0,
                            l = void 0;
                        (r = e ? document.documentElement : t), (o.clientWidth = r.clientWidth), (o.clientHeight = r.clientHeight);
                        var u = 0,
                            s = 0;
                        if (!n) {
                            var d = void 0,
                                c = void 0;
                            e
                                ? ((d = Pt(t, 'x')),
                                  (c = Pt(t, 'y')),
                                  (i = getComputedStyle(document.documentElement, '')),
                                  (a = getComputedStyle(document.body, '')),
                                  (u = Pt(
                                      t,
                                      'x',
                                      document.documentElement.scrollWidth +
                                          o.clientWidth +
                                          [
                                              'marginLeft',
                                              'marginRight',
                                              'borderLeftWidth',
                                              'borderRightWidth',
                                              'paddingLeft',
                                              'paddingRight',
                                          ].reduce(function (t, e) {
                                              return t + (parseFloat(i[e]) || 0) + (parseFloat(a[e]) || 0);
                                          }, 0),
                                  )),
                                  (s = Pt(
                                      t,
                                      'y',
                                      document.documentElement.scrollHeight +
                                          o.clientHeight +
                                          [
                                              'marginTop',
                                              'marginBottom',
                                              'borderTopWidth',
                                              'borderBottomWidth',
                                              'paddingTop',
                                              'paddingBottom',
                                          ].reduce(function (t, e) {
                                              return t + (parseFloat(i[e]) || 0) + (parseFloat(a[e]) || 0);
                                          }, 0),
                                  )),
                                  Pt(t, 'x', d),
                                  Pt(t, 'y', c))
                                : ((d = _t(t, 'x')),
                                  (c = _t(t, 'y')),
                                  (l = getComputedStyle(t, '')),
                                  (u = _t(
                                      t,
                                      'x',
                                      t.scrollWidth +
                                          o.clientWidth +
                                          [
                                              'marginLeft',
                                              'marginRight',
                                              'borderLeftWidth',
                                              'borderRightWidth',
                                              'paddingLeft',
                                              'paddingRight',
                                          ].reduce(function (t, e) {
                                              return t + (parseFloat(l[e]) || 0);
                                          }, 0),
                                  )),
                                  (s = _t(
                                      t,
                                      'y',
                                      t.scrollHeight +
                                          o.clientHeight +
                                          [
                                              'marginTop',
                                              'marginBottom',
                                              'borderTopWidth',
                                              'borderBottomWidth',
                                              'paddingTop',
                                              'paddingBottom',
                                          ].reduce(function (t, e) {
                                              return t + (parseFloat(l[e]) || 0);
                                          }, 0),
                                  )),
                                  _t(t, 'x', d),
                                  _t(t, 'y', c));
                        }
                        (o.scrollWidth = o.clientWidth + u), (o.scrollHeight = o.clientHeight + s);
                        var f = void 0;
                        return (
                            e
                                ? (o.clientX = o.clientY = 0)
                                : ((f = t.getBoundingClientRect()),
                                  l || (l = getComputedStyle(t, '')),
                                  (o.clientX = f.left + (parseFloat(l.borderLeftWidth) || 0)),
                                  (o.clientY = f.top + (parseFloat(l.borderTopWidth) || 0))),
                            o
                        );
                    })(c.target, d.isWindow, f),
                    v = Lt({ left: p.clientX, top: p.clientY, width: p.clientWidth, height: p.clientHeight });
                f
                    ? t.autoScroll && ((d.scrollWidth = t.autoScroll.scrollWidth), (d.scrollHeight = t.autoScroll.scrollHeight))
                    : ((d.scrollWidth = p.scrollWidth), (d.scrollHeight = p.scrollHeight)),
                    [
                        ['X', 'Width', 'left', 'right'],
                        ['Y', 'Height', 'top', 'bottom'],
                    ].forEach(function (t) {
                        var e = t[0],
                            n = t[1],
                            o = t[2],
                            i = t[3],
                            a = (d['scroll' + n] || 0) - p['client' + n],
                            l = c['min' + e] || 0,
                            u = nt(c['max' + e]) ? c['max' + e] : a;
                        if (l < u && l < a) {
                            u > a && (u = a);
                            for (var s = [], f = r[n.toLowerCase()], h = c.sensitivity.length - 1; h >= 0; h--) {
                                var m = c.sensitivity[h],
                                    g = c.speed[h];
                                s.push({ dir: -1, speed: g, position: v[o] + m }), s.push({ dir: 1, speed: g, position: v[i] - m - f });
                            }
                            d[e.toLowerCase()] = { min: l, max: u, lines: s };
                        }
                    });
            }
            t.autoScroll = d.x || d.y ? d : null;
        }
        function ee(t) {
            St.stop(),
                Nt(t.options.handle, t.orgCursor),
                (st.style.cursor = ft),
                !1 !== t.options.zIndex && (t.elementStyle.zIndex = t.orgZIndex),
                ht && (st.style[ht] = mt);
            var e = W(t.element);
            bt && e.remove(bt),
                wt && e.remove(wt),
                (lt = null),
                it.cancel(),
                t.onDragEnd && t.onDragEnd({ left: t.elementBBox.left, top: t.elementBBox.top });
        }
        function ne(t, e) {
            var n = t.options,
                r = void 0;
            if (e.containment) {
                var o = void 0;
                Yt(e.containment)
                    ? e.containment !== n.containment && ((n.containment = e.containment), (t.containmentIsBBox = !1), (r = !0))
                    : (o = jt(Dt(e.containment))) && Xt(o, n.containment) && ((n.containment = o), (t.containmentIsBBox = !0), (r = !0));
            }
            function i(t, e) {
                function n(t) {
                    return 'string' == typeof t ? t.replace(/[, ]+/g, ' ').trim().toLowerCase() : null;
                }
                nt(e.gravity) && e.gravity > 0 && (t.gravity = e.gravity);
                var r = n(e.corner);
                if (r) {
                    if ('all' !== r) {
                        var o = {},
                            i = r.split(/\s/).reduce(function (t, e) {
                                return (
                                    (e =
                                        'tl' === (e = e.trim().replace(/^(.).*?-(.).*$/, '$1$2')) || 'lt' === e
                                            ? 'tl'
                                            : 'tr' === e || 'rt' === e
                                            ? 'tr'
                                            : 'bl' === e || 'lb' === e
                                            ? 'bl'
                                            : 'br' === e || 'rb' === e
                                            ? 'br'
                                            : null) &&
                                        !o[e] &&
                                        (t.push(e), (o[e] = !0)),
                                    t
                                );
                            }, []),
                            a = i.length;
                        r = a ? (4 === a ? 'all' : i.join(' ')) : null;
                    }
                    r && (t.corner = r);
                }
                var l = n(e.side);
                l &&
                    ('start' === l || 'end' === l || 'both' === l
                        ? (t.side = l)
                        : ('start end' !== l && 'end start' !== l) || (t.side = 'both')),
                    'boolean' == typeof e.center && (t.center = e.center);
                var u = n(e.edge);
                u &&
                    ('inside' === u || 'outside' === u || 'both' === u
                        ? (t.edge = u)
                        : ('inside outside' !== u && 'outside inside' !== u) || (t.edge = 'both'));
                var s = 'string' == typeof e.base ? e.base.trim().toLowerCase() : null;
                return !s || ('containment' !== s && 'document' !== s) || (t.base = s), t;
            }
            if (null != e.snap) {
                var a = et(e.snap) && null != e.snap.targets ? e.snap : { targets: e.snap },
                    l = [],
                    u = i({ targets: l }, a);
                u.gravity || (u.gravity = 20),
                    u.corner || (u.corner = 'tl'),
                    u.side || (u.side = 'both'),
                    'boolean' != typeof u.center && (u.center = !1),
                    u.edge || (u.edge = 'both'),
                    u.base || (u.base = 'containment');
                var s = (Array.isArray(a.targets) ? a.targets : [a.targets]).reduce(function (t, e) {
                    if (null == e) return t;
                    var n = Yt(e),
                        r = jt(Dt(e)),
                        o = n || r ? { boundingBox: e } : et(e) && null == e.start && null == e.end && null == e.step ? e : { x: e, y: e },
                        a = [],
                        s = {},
                        d = o.boundingBox,
                        c = void 0;
                    if (n || Yt(d)) a.push({ element: d }), (s.boundingBox = d);
                    else if ((c = r || jt(Dt(d)))) a.push({ ppBBox: c }), (s.boundingBox = Rt(c));
                    else {
                        var f = void 0,
                            p = ['x', 'y'].reduce(function (t, e) {
                                var n,
                                    r = o[e];
                                if ((n = At(r))) (t[e] = n), (s[e] = Ft(n));
                                else {
                                    var i = void 0,
                                        a = void 0,
                                        l = void 0;
                                    et(r) &&
                                        ((i = At(r.start)),
                                        (a = At(r.end)),
                                        (l = At(r.step)),
                                        i && a && i.isRatio === a.isRatio && i.value >= a.value && (f = !0)),
                                        (i = t[e + 'Start'] = i || { value: 0, isRatio: !1 }),
                                        (a = t[e + 'End'] = a || { value: 1, isRatio: !0 }),
                                        (s[e] = { start: Ft(i), end: Ft(a) }),
                                        l &&
                                            ((l.isRatio ? l.value > 0 : l.value >= 2)
                                                ? ((t[e + 'Step'] = l), (s[e].step = Ft(l)))
                                                : (f = !0));
                                }
                                return t;
                            }, {});
                        if (f) return t;
                        p.xStart && !p.xStep && p.yStart && !p.yStep
                            ? a.push(
                                  { xStart: p.xStart, xEnd: p.xEnd, y: p.yStart },
                                  { xStart: p.xStart, xEnd: p.xEnd, y: p.yEnd },
                                  { x: p.xStart, yStart: p.yStart, yEnd: p.yEnd },
                                  { x: p.xEnd, yStart: p.yStart, yEnd: p.yEnd },
                              )
                            : a.push(p);
                    }
                    if (a.length) {
                        l.push(i(s, o));
                        var v = s.corner || u.corner,
                            h = s.side || u.side,
                            m = s.edge || u.edge,
                            g = {
                                gravity: s.gravity || u.gravity,
                                base: s.base || u.base,
                                center: 'boolean' == typeof s.center ? s.center : u.center,
                                corners: 'all' === v ? V : v.split(' '),
                                sides: 'both' === h ? G : [h],
                                edges: 'both' === m ? q : [m],
                            };
                        a.forEach(function (e) {
                            ['gravity', 'corners', 'sides', 'center', 'edges', 'base'].forEach(function (t) {
                                e[t] = g[t];
                            }),
                                t.push(e);
                        });
                    }
                    return t;
                }, []);
                s.length && ((n.snap = u), Xt(s, t.parsedSnapTargets) && ((t.parsedSnapTargets = s), (r = !0)));
            } else e.hasOwnProperty('snap') && t.parsedSnapTargets && (n.snap = t.parsedSnapTargets = t.snapTargets = void 0);
            if (e.autoScroll) {
                var d = et(e.autoScroll) ? e.autoScroll : { target: !0 === e.autoScroll ? window : e.autoScroll },
                    c = {};
                (c.target = Yt(d.target) ? d.target : window),
                    (c.speed = []),
                    (Array.isArray(d.speed) ? d.speed : [d.speed]).every(function (t, e) {
                        return !!(e <= 2 && nt(t)) && ((c.speed[e] = t), !0);
                    }),
                    c.speed.length || (c.speed = U);
                var f = Array.isArray(d.sensitivity) ? d.sensitivity : [d.sensitivity];
                (c.sensitivity = c.speed.map(function (t, e) {
                    return nt(f[e]) ? f[e] : Z[e];
                })),
                    ['X', 'Y'].forEach(function (t) {
                        var e = 'min' + t,
                            n = 'max' + t;
                        nt(d[e]) && d[e] >= 0 && (c[e] = d[e]), nt(d[n]) && d[n] >= 0 && (!c[e] || d[n] >= c[e]) && (c[n] = d[n]);
                    }),
                    Xt(c, n.autoScroll) && ((n.autoScroll = c), (r = !0));
            } else e.hasOwnProperty('autoScroll') && (n.autoScroll && (r = !0), (n.autoScroll = void 0));
            if ((r && te(t), Yt(e.handle) && e.handle !== n.handle)) {
                n.handle &&
                    ((n.handle.style.cursor = t.orgCursor),
                    ht && (n.handle.style[ht] = t.orgUserSelect),
                    it.removeStartHandler(n.handle, t.pointerEventHandlerId));
                var p = (n.handle = e.handle);
                (t.orgCursor = p.style.cursor),
                    Nt(p, t.orgCursor),
                    ht && ((t.orgUserSelect = p.style[ht]), (p.style[ht] = 'none')),
                    it.addStartHandler(p, t.pointerEventHandlerId);
            }
            (nt(e.zIndex) || !1 === e.zIndex) &&
                ((n.zIndex = e.zIndex), t === lt && (t.elementStyle.zIndex = !1 === n.zIndex ? t.orgZIndex : n.zIndex));
            var v = { left: t.elementBBox.left, top: t.elementBBox.top },
                h = void 0;
            nt(e.left) && e.left !== v.left && ((v.left = e.left), (h = !0)),
                nt(e.top) && e.top !== v.top && ((v.top = e.top), (h = !0)),
                h && $t(t, v),
                ['onDrag', 'onMove', 'onDragStart', 'onMoveStart', 'onDragEnd'].forEach(function (r) {
                    'function' == typeof e[r]
                        ? ((n[r] = e[r]), (t[r] = n[r].bind(t.ins)))
                        : e.hasOwnProperty(r) && null == e[r] && (n[r] = t[r] = void 0);
                });
        }
        (St.move = function (t, e, n) {
            Tt.call(window, It),
                Bt(),
                Ht === t && (e.x && Ot.x && (e.x.lastValue = Ot.x.lastValue), e.y && Ot.y && (e.y.lastValue = Ot.y.lastValue)),
                (Ht = t),
                (Ot = e),
                (kt = n);
            var r = Date.now();
            ['x', 'y'].forEach(function (t) {
                var e = Ot[t];
                e && (e.lastFrameTime = r);
            }),
                (It = Et.call(window, Ct));
        }),
            (St.stop = function () {
                Tt.call(window, It), Bt(), (Ot = {}), (Ht = null);
            });
        var re = (function () {
            function t(e, n) {
                !(function (t, e) {
                    if (!(t instanceof e)) throw new TypeError('Cannot call a class as a function');
                })(this, t);
                var r = { ins: this, options: { zIndex: 9e3 }, disabled: !1 };
                if ((Object.defineProperty(this, '_id', { value: ++at }), (r._id = this._id), (rt[this._id] = r), !Yt(e) || e === st))
                    throw new Error('This element is not accepted.');
                if (n) {
                    if (!et(n)) throw new Error('Invalid options.');
                } else n = {};
                var o = !0,
                    i = void 0;
                if (e instanceof SVGElement && (i = e.ownerSVGElement)) {
                    if (!e.getBBox) throw new Error('This element is not accepted. (SVGLocatable)');
                    if (!e.transform) throw new Error('This element is not accepted. (SVGAnimatedTransformList)');
                    (r.svgTransform = e.transform.baseVal.appendItem(i.createSVGTransform())), (r.svgPoint = i.createSVGPoint());
                    var a = e.nearestViewportElement;
                    (r.svgCtmElement = K ? a.appendChild(document.createElementNS(i.namespaceURI, 'rect')) : a),
                        (o = !1),
                        (r.initElm = Qt),
                        (r.moveElm = Zt);
                } else {
                    var l = D.getName('willChange');
                    l && (o = !1),
                        !n.leftTop && vt
                            ? (l && (e.style[l] = 'transform'), (r.initElm = Jt), (r.moveElm = qt))
                            : (l && (e.style[l] = 'left, top'), (r.initElm = Kt), (r.moveElm = Ut));
                }
                if (
                    ((r.element = (function (t, e) {
                        var n = t.style;
                        n.webkitTapHighlightColor = 'transparent';
                        var r = D.getName('boxShadow'),
                            o = window.getComputedStyle(t, '')[r];
                        return (o && 'none' !== o) || (n[r] = '0 0 1px transparent'), e && vt && (n[vt] = 'translateZ(0)'), t;
                    })(e, o)),
                    (r.elementStyle = e.style),
                    (r.orgZIndex = r.elementStyle.zIndex),
                    xt && W(e).add(xt),
                    (r.pointerEventHandlerId = it.regStartHandler(function (t) {
                        return (function (t, e) {
                            return (
                                !t.disabled &&
                                (!t.onDragStart || !1 !== t.onDragStart(e)) &&
                                (lt && ee(lt),
                                Vt(t.options.handle),
                                (st.style.cursor = ct || window.getComputedStyle(t.options.handle, '').cursor),
                                !1 !== t.options.zIndex && (t.elementStyle.zIndex = t.options.zIndex),
                                ht && (st.style[ht] = 'none'),
                                wt && W(t.element).add(wt),
                                (lt = t),
                                (ut = !1),
                                (ot.left = t.elementBBox.left - (e.clientX + window.pageXOffset)),
                                (ot.top = t.elementBBox.top - (e.clientY + window.pageYOffset)),
                                !0)
                            );
                        })(r, t);
                    })),
                    !n.containment)
                ) {
                    var u;
                    n.containment = (u = e.parentNode) && Yt(u) ? u : st;
                }
                n.handle || (n.handle = e), ne(r, n);
            }
            return (
                j(
                    t,
                    [
                        {
                            key: 'remove',
                            value: function () {
                                var t = rt[this._id];
                                (this.disabled = !0),
                                    it.unregStartHandler(it.removeStartHandler(t.options.handle, t.pointerEventHandlerId)),
                                    delete rt[this._id];
                            },
                        },
                        {
                            key: 'setOptions',
                            value: function (t) {
                                return et(t) && ne(rt[this._id], t), this;
                            },
                        },
                        {
                            key: 'position',
                            value: function () {
                                return te(rt[this._id]), this;
                            },
                        },
                        {
                            key: 'disabled',
                            get: function () {
                                return rt[this._id].disabled;
                            },
                            set: function (t) {
                                var e = rt[this._id];
                                (t = !!t) !== e.disabled &&
                                    ((e.disabled = t),
                                    e.disabled
                                        ? (e === lt && ee(e),
                                          (e.options.handle.style.cursor = e.orgCursor),
                                          ht && (e.options.handle.style[ht] = e.orgUserSelect),
                                          xt && W(e.element).remove(xt))
                                        : (Nt(e.options.handle, e.orgCursor),
                                          ht && (e.options.handle.style[ht] = 'none'),
                                          xt && W(e.element).add(xt)));
                            },
                        },
                        {
                            key: 'element',
                            get: function () {
                                return rt[this._id].element;
                            },
                        },
                        {
                            key: 'rect',
                            get: function () {
                                return Dt(rt[this._id].elementBBox);
                            },
                        },
                        {
                            key: 'left',
                            get: function () {
                                return rt[this._id].elementBBox.left;
                            },
                            set: function (t) {
                                ne(rt[this._id], { left: t });
                            },
                        },
                        {
                            key: 'top',
                            get: function () {
                                return rt[this._id].elementBBox.top;
                            },
                            set: function (t) {
                                ne(rt[this._id], { top: t });
                            },
                        },
                        {
                            key: 'containment',
                            get: function () {
                                var t = rt[this._id];
                                return t.containmentIsBBox ? Rt(t.options.containment) : t.options.containment;
                            },
                            set: function (t) {
                                ne(rt[this._id], { containment: t });
                            },
                        },
                        {
                            key: 'snap',
                            get: function () {
                                return Dt(rt[this._id].options.snap);
                            },
                            set: function (t) {
                                ne(rt[this._id], { snap: t });
                            },
                        },
                        {
                            key: 'autoScroll',
                            get: function () {
                                return Dt(rt[this._id].options.autoScroll);
                            },
                            set: function (t) {
                                ne(rt[this._id], { autoScroll: t });
                            },
                        },
                        {
                            key: 'handle',
                            get: function () {
                                return rt[this._id].options.handle;
                            },
                            set: function (t) {
                                ne(rt[this._id], { handle: t });
                            },
                        },
                        {
                            key: 'zIndex',
                            get: function () {
                                return rt[this._id].options.zIndex;
                            },
                            set: function (t) {
                                ne(rt[this._id], { zIndex: t });
                            },
                        },
                        {
                            key: 'onDrag',
                            get: function () {
                                return rt[this._id].options.onDrag;
                            },
                            set: function (t) {
                                ne(rt[this._id], { onDrag: t });
                            },
                        },
                        {
                            key: 'onMove',
                            get: function () {
                                return rt[this._id].options.onMove;
                            },
                            set: function (t) {
                                ne(rt[this._id], { onMove: t });
                            },
                        },
                        {
                            key: 'onDragStart',
                            get: function () {
                                return rt[this._id].options.onDragStart;
                            },
                            set: function (t) {
                                ne(rt[this._id], { onDragStart: t });
                            },
                        },
                        {
                            key: 'onMoveStart',
                            get: function () {
                                return rt[this._id].options.onMoveStart;
                            },
                            set: function (t) {
                                ne(rt[this._id], { onMoveStart: t });
                            },
                        },
                        {
                            key: 'onDragEnd',
                            get: function () {
                                return rt[this._id].options.onDragEnd;
                            },
                            set: function (t) {
                                ne(rt[this._id], { onDragEnd: t });
                            },
                        },
                    ],
                    [
                        {
                            key: 'draggableCursor',
                            get: function () {
                                return gt;
                            },
                            set: function (t) {
                                gt !== t &&
                                    ((gt = t),
                                    (dt = null),
                                    Object.keys(rt).forEach(function (t) {
                                        var e = rt[t];
                                        e.disabled ||
                                            (e === lt && !1 !== ct) ||
                                            (Nt(e.options.handle, e.orgCursor),
                                            e === lt &&
                                                ((st.style.cursor = ft),
                                                (st.style.cursor = window.getComputedStyle(e.options.handle, '').cursor)));
                                    }));
                            },
                        },
                        {
                            key: 'draggingCursor',
                            get: function () {
                                return yt;
                            },
                            set: function (t) {
                                yt !== t &&
                                    ((yt = t),
                                    (ct = null),
                                    lt &&
                                        (Vt(lt.options.handle),
                                        !1 === ct && (Nt(lt.options.handle, lt.orgCursor), (st.style.cursor = ft)),
                                        (st.style.cursor = ct || window.getComputedStyle(lt.options.handle, '').cursor)));
                            },
                        },
                        {
                            key: 'draggableClass',
                            get: function () {
                                return xt;
                            },
                            set: function (t) {
                                (t = t ? t + '' : void 0) !== xt &&
                                    (Object.keys(rt).forEach(function (e) {
                                        var n = rt[e];
                                        if (!n.disabled) {
                                            var r = W(n.element);
                                            xt && r.remove(xt), t && r.add(t);
                                        }
                                    }),
                                    (xt = t));
                            },
                        },
                        {
                            key: 'draggingClass',
                            get: function () {
                                return wt;
                            },
                            set: function (t) {
                                if ((t = t ? t + '' : void 0) !== wt) {
                                    if (lt) {
                                        var e = W(lt.element);
                                        wt && e.remove(wt), t && e.add(t);
                                    }
                                    wt = t;
                                }
                            },
                        },
                        {
                            key: 'movingClass',
                            get: function () {
                                return bt;
                            },
                            set: function (t) {
                                if ((t = t ? t + '' : void 0) !== bt) {
                                    if (lt && ut) {
                                        var e = W(lt.element);
                                        bt && e.remove(bt), t && e.add(t);
                                    }
                                    bt = t;
                                }
                            },
                        },
                    ],
                ),
                t
            );
        })();
        it.addMoveHandler(document, function (t) {
            if (lt) {
                var e = { left: t.clientX + window.pageXOffset + ot.left, top: t.clientY + window.pageYOffset + ot.top };
                if (
                    $t(
                        lt,
                        e,
                        lt.snapTargets
                            ? function (t) {
                                  var e = lt.snapTargets.length,
                                      n = !1,
                                      r = !1,
                                      o = void 0;
                                  for (o = 0; o < e && (!n || !r); o++) {
                                      var i = lt.snapTargets[o];
                                      (null == i.gravityXStart || t.left >= i.gravityXStart) &&
                                          (null == i.gravityXEnd || t.left <= i.gravityXEnd) &&
                                          (null == i.gravityYStart || t.top >= i.gravityYStart) &&
                                          (null == i.gravityYEnd || t.top <= i.gravityYEnd) &&
                                          (n || null == i.x || ((t.left = i.x), (n = !0), (o = -1)),
                                          r || null == i.y || ((t.top = i.y), (r = !0), (o = -1)));
                                  }
                                  return (t.snapped = n || r), !lt.onDrag || lt.onDrag(t);
                              }
                            : lt.onDrag,
                    )
                ) {
                    var n = {},
                        r = lt.autoScroll;
                    if (r) {
                        var o = { x: lt.elementBBox.left - window.pageXOffset, y: lt.elementBBox.top - window.pageYOffset };
                        ['x', 'y'].forEach(function (t) {
                            if (r[t]) {
                                var e = r[t].min,
                                    i = r[t].max;
                                r[t].lines.some(function (r) {
                                    return (
                                        (-1 === r.dir ? o[t] <= r.position : o[t] >= r.position) &&
                                        ((n[t] = { dir: r.dir, speed: r.speed / 1e3, min: e, max: i }), !0)
                                    );
                                });
                            }
                        });
                    }
                    n.x || n.y ? (St.move(r.target, n, r.isWindow ? Pt : _t), (e.autoScroll = !0)) : St.stop(),
                        ut || ((ut = !0), bt && W(lt.element).add(bt), lt.onMoveStart && lt.onMoveStart(e)),
                        lt.onMove && lt.onMove(e);
                }
            }
        });
        var oe = function () {
            lt && ee(lt);
        };
        it.addEndHandler(document, oe), it.addCancelHandler(document, oe);
        var ie = function () {
            (pt = D.getName('transitionProperty')),
                (vt = D.getName('transform')),
                (ft = st.style.cursor),
                (ht = D.getName('userSelect')) && (mt = st.style[ht]);
            var t = {},
                e = void 0;
            function n(t, e) {
                t.initElm && te(t, e);
            }
            var r = !1,
                o = d.add(function (o) {
                    r ||
                        ((r = !0),
                        lt && (n(lt, o.type), it.move(), (t[lt._id] = !0)),
                        clearTimeout(e),
                        (e = setTimeout(function () {
                            var r;
                            (r = o.type),
                                clearTimeout(e),
                                Object.keys(rt).forEach(function (e) {
                                    t[e] || n(rt[e], r);
                                }),
                                (t = {});
                        }, 200)),
                        (r = !1));
                });
            window.addEventListener('resize', o, !0), window.addEventListener('scroll', o, !0);
        };
        (st = document.body)
            ? ie()
            : document.addEventListener(
                  'DOMContentLoaded',
                  function () {
                      (st = document.body), ie();
                  },
                  !0,
              );
        e.default = re;
    },
]);
