'use strict';

import * as fengari from 'fengari-web';
import { Hook } from '@edunad/hooks';

import { FloppyData } from '$models/floppyData';

declare const window: any;
export class FloppyService {
    public static loadedFloppy: FloppyData;

    private static floppies: { [id: string]: FloppyData };
    private static sandbox: HTMLDivElement;

    public static onFloppyLoad: Hook<FloppyData> = new Hook<FloppyData>();
    public static onFloppyUnload: Hook<void> = new Hook<void>();

    public static init(el: HTMLDivElement): void {
        this.sandbox = el;
    }

    public static loadFloppies(onLoaded: () => void): void {
        this.floppies = {};
        this.setup();

        Promise.all([this.loadFloppy('caw'), this.loadFloppy('mew'), this.loadFloppy('forbidden')]).then(() => onLoaded());
    }

    public static isLoaded(): boolean {
        return this.loadedFloppy != null;
    }

    public static load(id: string): void {
        this.unload();

        try {
            this.loadedFloppy = this.get(id).loadLua();
            this.onFloppyLoad.emit(this.loadedFloppy);
        } catch (err) {
            console.error(`Failed to load {${id}}, lua error!`, err.message);
        }
    }

    public static unload(): void {
        this.sandbox.innerHTML = '';
        this.loadedFloppy = null; // TODO: Clean up lua

        this.onFloppyUnload.emit();
    }

    public static setup(): void {
        // TODO: Inject custom stuff into lua
        window.sandbox = this.sandbox;
    }

    private static loadFloppy(path: string): Promise<void> {
        return fetch(`./floppies/${path}/${path}.json`)
            .then((response: Response) => response.json())
            .then((json: FloppyData) => {
                return fetch(`./floppies/${path}/${json.luaPath}`)
                    .then((response: Response) => response.text())
                    .then((lua: string) => {
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        json.loadLua = function () {
                            fengari.load(lua)();
                            return this;
                        };

                        this.floppies[json.id] = json;
                    });
            });
    }

    public static get(id: string): FloppyData {
        const flp: FloppyData = this.floppies[id];
        if (!flp) throw new Error(`Invalid floppy {${id}}`);

        return flp;
    }

    public static getAll(): { [id: string]: FloppyData } {
        return this.floppies;
    }
}
