'use strict';

import { DOMService } from '$services/domService';
import { BrowserID } from '$enums/browserID';

export enum ConsoleLogType {
    LOG = 0,
    WARN = 1,
    ERROR = 2,
}

const LOG_LIMIT: number = 10;

/**
 * Debug service, prints debug messages on the console
 * @class
 *
 */
export class DebugService {
    private static supportsColors: boolean = true;
    private static groups: { [id: string]: string[] } = {};

    private static oError: any;
    private static oWarn: any;

    public static init(): void {
        this.oError = console.error.bind(console);
        this.oWarn = console.warn.bind(console);

        console.error = this.onError.bind(this);
        console.warn = this.onWarn.bind(this);
    }

    private static onError(...text: string[]): void {
        this.onDebug('ERRORS', ...text);
    }

    private static onWarn(...text: string[]): void {
        this.onDebug('WARNINGS', ...text);
    }
    /**
     * Returns true if debugmode is enabled
     *
     * @returns {boolean}
     */
    public static isDebugEnabled(): boolean {
        return !(window['__PRODUCTION__'] as boolean);
    }

    /**
     * Pretty prints a debug message, if VIEWER_DEBUG is set to true
     *
     * @param {string} title - the debug title
     * @param {string} text - the debug message
     * @returns {void}
     */
    public static onDebug(title: string, ...text: string[]): boolean {
        //if (!this.isDebugEnabled()) return false; //- Always show debug messages, they fun.
        const browser: BrowserID = DOMService.getCurrentBrowser();
        if (browser == BrowserID.IE || browser == BrowserID.UNKNOWN) {
            this.supportsColors = false;
        }

        if (this.groups[title] == null) this.groups[title] = [...text];
        else {
            if (this.groups[title].length >= LOG_LIMIT) this.groups[title].shift();
            this.groups[title].push(...text);
        }

        this.drawMessage();
        return true;
    }

    private static drawMessage(): void {
        console.clear();
        this.displayDebugWarning();

        const title: number = 69;

        const groups: [string, string[]][] = Object.entries(this.groups);
        for (let i: number = 0; i < groups.length; i++) {
            const data: [string, string[]] = groups[i];
            let groupId: string = data[0];
            const isWarning: boolean = groupId === 'WARNINGS';
            const isError: boolean = groupId === 'ERRORS';

            let groupColor: string = '#3498db';
            if (groupId === 'WARNINGS') groupColor = '#f1c40f';
            else if (groupId === 'ERRORS') groupColor = '#e74c3c';

            if (!isWarning && !isError) groupId = 'DEBUG - ' + groupId;
            console.group(`%c ${groupId.padEnd(title, ' ')}`, `color: ${groupColor}; background: black;`);
            for (let o: number = 0; o < data[1].length; o++) {
                if (isError) {
                    this.oError(data[1][o]);
                } else if (isWarning) {
                    this.oWarn(data[1][o]);
                } else {
                    this.consoleDebug(`%c${data[1][o]}`, `color: ${groupColor};`);
                }
            }

            console.groupEnd();
        }

        this.consoleDebug(`%c`.padEnd(title + 3, ' ') + '\n' + ''.padEnd(title + 1, ' '), `background: black;`);
    }

    /**
     * Displays a debug warning and how to disable debug mode
     *
     * @returns {void}
     */
    private static displayDebugWarning(): void {
        this.consoleDebug(
            `%c
 %c                              [Caw]                                 ⠀
                                                                    ⠀ `,
            'color: white; background: black;',
            'color: #e74c3c; background: black;',
        );
    }

    /**
     * Prints with colors if the browser supports it
     * @param {string} text - The locale key
     * @param {...string[]} colors - Collection of params with colors
     *
     * @returns {void}
     */
    private static consoleDebug(text: string, ...colors: string[]): void {
        if (!this.supportsColors) {
            console.debug(text.replace(/%c/g, ''));
        } else {
            console.debug(text, ...colors);
        }
    }
}
