'use strict';

/**
 * The current browser ID
 * @enum
 *
 */
export enum BrowserID {
    FIREFOX = 0,
    CHROME = 1,
    EDGE = 2,
    IE = 3,
    UNKNOWN = 4,
}
