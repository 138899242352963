'use strict';
import { BrowserID } from '$enums/browserID';

/**
 * DOM service containing various utility functions
 * @class
 *
 */
export class DOMService {
    /**
     * Gets the HTML from a given url
     *
     * @param {string} url - the url
     * @param {(err: string, body: string) => void} callback - the callback function
     * @returns {void}
     */
    public static getHTMLFromURL(url: string, callback: (err: string, body: string) => void): void {
        if (url == null || url === '') return callback('Invalid Url', null);

        const xhttp: XMLHttpRequest = new XMLHttpRequest();
        xhttp.onreadystatechange = function (): void {
            if (this.readyState === 4) {
                if (this.status !== 200) {
                    return callback('Failed to gather response', null);
                }

                return callback(null, this.responseText);
            }
        };

        xhttp.open('GET', url, true);
        xhttp.timeout = 10000;

        xhttp.setRequestHeader('Content-Type', 'application/text');
        xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhttp.send();
    }

    /**
     * Returns true if the given stylesheet is already injected
     *
     * @param {string} cssPath - stylesheet path
     * @returns {boolean}
     */
    public static isCSSInjected(cssPath: string): boolean {
        return document.querySelector('link[rel=stylesheet][href="' + cssPath + '"]') != null;
    }

    /**
     * Injects a css stylesheet from a given path
     *
     * @param {string} cssPath - stylesheet path
     * @returns {void}
     */
    public static injectCSS(cssPath: string): boolean {
        if (this.isCSSInjected(cssPath)) {
            return false;
        }

        const linkElement: HTMLElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('media', 'all');
        linkElement.setAttribute('href', cssPath);

        document.head.appendChild(linkElement);
        return true;
    }

    /**
     * Retrieves value for a given parameter name.
     *
     * @param {string} parameterName - Parameter name.
     * @returns {string}
     */
    public static getQueryStringParameter(url: string, parameterName: string): string {
        const parameterValue: string = null;

        if (typeof parameterName !== typeof undefined) {
            parameterName = parameterName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

            const expression: string = `[\\?&]${parameterName.toLowerCase()}=([^&#]*)`;
            const regEx: RegExp = new RegExp(expression);
            const results: RegExpExecArray = regEx.exec(url.toLowerCase());

            if (results !== null) {
                return decodeURIComponent(results[1].replace(/\+/g, ' ').replace(/"/g, ''));
            }
        }

        return parameterValue;
    }

    public static containsClass(target: HTMLElement, name: string): boolean {
        if (target == null) return false;

        const classList: any = [...(target.classList as any)];
        if (classList == null || classList.length <= 0) return false;

        return classList.find((key: string) => {
            return key.indexOf(name) !== -1;
        });
    }

    /**
     * Retrieves the current browserID
     *
     * @returns {BrowserID}
     */
    public static getCurrentBrowser(): BrowserID {
        if (/firefox/i.test(navigator.userAgent)) return BrowserID.FIREFOX;
        else if ((document as any).documentMode) return BrowserID.IE;
        else if (window.navigator.userAgent.indexOf('Edge') > -1) return BrowserID.EDGE;
        else if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) return BrowserID.CHROME;

        return BrowserID.UNKNOWN;
    }
}
