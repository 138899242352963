'use strict';

import { Component, createRef, RefObject } from 'react';
import { HookSubscription } from '@edunad/hooks';

import { SoundService } from '$services/soundService';
import { FloppyService } from '$services/floppyService';
import { Sound } from '$models/sound';

interface ConsoleState {
    hasFloppy: boolean;
}

/**
 * Console component
 * @class
 */
export class Console extends Component<any, ConsoleState> {
    private floppyLoadSubscription: HookSubscription;
    private floppyUnLoadSubscription: HookSubscription;

    private ambient: Sound;
    private timeout: any;

    private led: RefObject<HTMLDivElement>;

    public constructor(props: any) {
        super(props);

        this.led = createRef();
        this.state = {
            hasFloppy: false,
        };
    }

    public componentWillUnmount(): void {
        this.floppyLoadSubscription.destroy();
        this.floppyUnLoadSubscription.destroy();
    }

    public componentDidMount(): void {
        this.setState({ hasFloppy: FloppyService.isLoaded() });
        if (this.state.hasFloppy) this.startAmbient();

        this.floppyLoadSubscription = FloppyService.onFloppyLoad.addUnique(() => {
            this.setState({ hasFloppy: true });
            this.startAmbient();
        });

        this.floppyUnLoadSubscription = FloppyService.onFloppyUnload.addUnique(() => {
            this.led.current.classList.remove('blink');
            this.setState({ hasFloppy: false });
            this.stopAmbient();
        });
    }

    private eject(): void {
        if (!this.state.hasFloppy) return;

        SoundService.get('eject').play();
        FloppyService.unload();
    }

    private stopAmbient(): void {
        clearTimeout(this.timeout);

        if (this.ambient == null) return;
        this.ambient.stop();
    }

    private startAmbient(): void {
        this.stopAmbient();

        this.ambient = SoundService.get('running').setVolume(0.3).setLoop(true);
        this.ambient.play();

        this.randomAccess();
    }

    private randomAccess(): void {
        this.timeout = setTimeout(() => {
            if (!this.state.hasFloppy) return;
            this.led.current.classList.add('blink');

            SoundService.get(`access_${Math.getRandom(1, 6)}`)
                .setVolume(0.1)
                .play();

            this.randomAccess();
        }, Math.getRandom(1000, 7000));
    }

    private animEnd(): void {
        if (this.led.current == null) return;
        this.led.current.classList.remove('blink');
    }

    /**
     * React render method
     *
     * @returns {any}
     */
    public render(): any {
        return (
            <div className="console-container">
                <img style={{ zIndex: 12 }} src="../../assets/img/caw_console/cc_top.png" />
                {this.state.hasFloppy && (
                    <img style={{ zIndex: 11 }} className="console-floppy" src="../../assets/img/caw_console/cc_floppy.png" />
                )}

                <img style={{ zIndex: 10 }} src="../../assets/img/caw_console/cc_bottom.png" />

                <div className="console-led-container">
                    <div className="console-led" style={{ backgroundColor: this.state.hasFloppy ? '#fff' : '#000' }}></div>
                    <div ref={this.led} onAnimationEnd={this.animEnd.bind(this)} className="console-led"></div>
                    <div
                        className={`console-eject ${this.state.hasFloppy ? 'enabled' : ''}`}
                        onClick={this.state.hasFloppy ? this.eject.bind(this) : null}></div>
                </div>
            </div>
        );
    }
}
